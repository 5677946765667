body{
    background-color: #e8e9eb;
}

.back-arrow{
    cursor: pointer;
}

.article-title{
    color: black;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
}

.article-title h1{
    color: black;
    font-size: 3rem;
}

.ui.text.container{
    padding-top: 3em;
    width: 40%;
}

.image-gallery{
    width: 70%;   
    margin: 5vh 15% !important;
}